<template>
  <div style="padding: 20px; height: 100%">
    <el-form :inline="true" class="demo-form-inline">
      <div class="df aic">
        <el-form-item size="medium" class="mr10" style="flex: 0 0 16%">
          <el-input
            placeholder="关键词"
            v-model="query.keyword"
            clearable
            class="input-with-select"
            @clear="onSubmit"
          >
          </el-input>
        </el-form-item>
        <el-button
          type="success"
          size="medium"
          @click="onSubmit"
          class="mr10"
          style="height: 36px; border: none !important; margin-bottom: 20px"
          >查询</el-button
        >
        <el-button
          type="primary"
          size="medium"
          class="mr10"
          style="height: 36px; border: none !important; margin-bottom: 20px"
          @click="
            dialogTitle = '新增';
            dialogVisible = true;
          "
          >新增</el-button
        >
      </div>
    </el-form>
    <el-table :data="list" style="width: 100%">
      <el-table-column
        prop="modeName"
        label="模式名称"
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="createdTime"
        label="创建时间"
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="address" label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            class="disib"
            size="mini"
            @click="handleEdit(scope.row)"
            >修改</el-button
          >
          <el-button
            type="text"
            class="disib red"
            size="mini"
            @click="delItem(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="df" style="justify-content: flex-end; padding: 20px 0">
      <el-pagination
        background
        :page-size="query.limit"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="query.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      @closed="
        editId = null;
        form = {
          name: '',
          steps: [[]],
        };
      "
    >
      <div style="height: 800px; overflow-y: scroll; overflow-x: hidden">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item
            label="模式名称"
            prop="name"
            :rules="{
              required: true,
              message: '名称不能为空',
              trigger: 'blur',
            }"
          >
            <div class="df aic" style="width: 88.5%">
              <div style="flex: 1">
                <el-input v-model="form.name"></el-input>
              </div>
              <el-button
                type="primary"
                size="medium"
                style="height: 40px; border: none !important; margin-left: 20px"
                @click="addItem"
                >新增步骤</el-button
              >
            </div>
          </el-form-item>

          <div style="padding: 0 20px">
            <div
              v-for="(item, index) in form.steps"
              :key="'I' + index"
              style="
                padding: 20px;
                background-color: #f8f8f8;
                margin-bottom: 10px;
              "
            >
              <el-form-item :label="'步骤' + (index + 1)">
                <div style="width: 80%">
                  <div
                    class="df"
                    style="justify-content: flex-end; padding: 5px 0"
                  >
                    <el-dropdown
                      @command="(command) => addComponent(command, index)"
                    >
                      <el-button
                        type="warning"
                        size="mini"
                        style="border: none !important; margin-left: 20px"
                        >新增元件</el-button
                      >
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="dItem in componentListComputed[index]"
                          :key="dItem.key"
                          :command="dItem"
                          >{{ dItem.name }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                    <div class="df aic jcc" v-if="form.steps.length > 1">
                      <el-button
                        type="danger"
                        size="mini"
                        style="
                          border: none !important;
                          margin-left: 20px;
                          height: 26px;
                        "
                        @click="delStep(index)"
                        >移除步骤</el-button
                      >
                    </div>
                  </div>
                  <template v-for="(dItem, dIndex) in item">
                    <el-card
                      class="box-card"
                      style="margin-bottom: 10px"
                      :key="'I' + index + 'D' + dIndex"
                    >
                      <div slot="header">
                        <div class="df jcb">
                          <span>{{ dItem.name }}</span>
                          <el-button
                            style="color: #f56c6c; padding: 0 !important"
                            type="text"
                            @click="delComponent(index, dItem)"
                            >移除</el-button
                          >
                        </div>
                      </div>
                      <el-form ref="form1" :model="dItem" label-width="120px">
                        <el-form-item
                          label="位置"
                          class="mt10"
                          prop="action.position"
                          v-if="
                            dItem.action &&
                            Object.keys(dItem.action).includes('position')
                          "
                          :rules="{
                            required: true,
                            message: '位置不能为空',
                            trigger: 'blur',
                          }"
                        >
                          <div style="width: 80%">
                            <el-input
                              v-model="dItem.action.position"
                            ></el-input>
                          </div>
                        </el-form-item>
                        <el-form-item
                          label="速度"
                          class="mt10"
                          v-if="
                            dItem.action &&
                            Object.keys(dItem.action).includes('seepd')
                          "
                          prop="action.seepd"
                          :rules="{
                            required: true,
                            message: '速度不能为空',
                            trigger: 'blur',
                          }"
                        >
                          <div style="width: 80%">
                            <el-input
                              :key="'FI' + index + 'seepd'"
                              v-model="dItem.action.seepd"
                            ></el-input>
                          </div>
                        </el-form-item>
                        <el-form-item
                          label="开关"
                          class="mt10"
                          v-if="
                            dItem.action &&
                            Object.keys(dItem.action).includes('switch')
                          "
                          prop="action.switch"
                          :rules="{
                            required: true,
                            message: '开关不能为空',
                            trigger: 'blur',
                          }"
                        >
                          <div
                            style="width: 80%; justify-content: flex-end"
                            class="df"
                          >
                            <el-switch v-model="dItem.action.switch">
                            </el-switch>
                          </div>
                        </el-form-item>
                        <el-form-item
                          label="开关"
                          class="mt10"
                          prop="action.sound"
                          :rules="{
                            required: true,
                            message: '开关不能为空',
                            trigger: 'blur',
                          }"
                          v-if="
                            dItem.action &&
                            Object.keys(dItem.action).includes('sound')
                          "
                        >
                          <div
                            style="width: 80%; justify-content: flex-end"
                            class="df"
                          >
                            <el-switch v-model="dItem.action.sound">
                            </el-switch>
                          </div>
                        </el-form-item>
                        <el-form-item
                          label="温度(℃)"
                          class="mt10"
                          prop="action.temperature"
                          :rules="{
                            required: true,
                            message: '温度不能为空',
                            trigger: 'blur',
                          }"
                          v-if="
                            dItem.action &&
                            Object.keys(dItem.action).includes('temperature')
                          "
                        >
                          <div style="width: 80%">
                            <el-input
                              :key="'FI' + index + 'temperature'"
                              v-model="dItem.action.temperature"
                            ></el-input>
                          </div>
                        </el-form-item>
                        <el-form-item
                          label="延时(s)"
                          class="mt10"
                          prop="action.delay"
                          :rules="{
                            required: true,
                            message: '延时不能为空',
                            trigger: 'blur',
                          }"
                          v-if="
                            dItem.action &&
                            Object.keys(dItem.action).includes('delay')
                          "
                        >
                          <div style="width: 80%">
                            <el-input
                              :key="'FI' + index + 'delay'"
                              v-model="dItem.action.delay"
                            ></el-input>
                          </div>
                        </el-form-item>
                        <el-form-item
                          label="颜色"
                          class="mt10"
                          prop="action.color"
                          :rules="{
                            required: true,
                            message: '颜色不能为空',
                            trigger: 'blur',
                          }"
                          v-if="
                            dItem.action &&
                            Object.keys(dItem.action).includes('color')
                          "
                        >
                          <div
                            style="width: 80%; justify-content: flex-end"
                            class="df"
                          >
                            <el-color-picker
                              v-model="dItem.action.color"
                            ></el-color-picker>
                          </div>
                        </el-form-item>
                        <el-form-item
                          label="浓度"
                          class="mt10"
                          prop="action.concentration"
                          v-if="
                            dItem.action &&
                            Object.keys(dItem.action).includes('concentration')
                          "
                        >
                          <div
                            style="
                              width: 80%;
                              padding: 0px 20px;
                              margin-bottom: 30px;
                            "
                            class=""
                          >
                            <el-input
                              :key="'FI' + index + 'concentration'"
                              v-model="dItem.action.concentration"
                              type="number"
                            ></el-input>
                            <!-- <el-slider
                              v-model="dItem.action.concentration"
                              :step="1"
                              :min="2"
                              :max="7"
                              :disabled="false"
                              :show-tooltip="false"
                              show-stops
                              :marks="sliderMarks"
                            >
                            </el-slider> -->
                          </div>
                        </el-form-item>
                        <!-- concentration -->
                        <el-form-item
                          label="模式"
                          class="mt10"
                          prop="action.color"
                          :rules="{
                            required: true,
                            message: '请选择模式',
                            trigger: 'blur',
                          }"
                          v-if="
                            dItem.action &&
                            Object.keys(dItem.action).includes('color')
                          "
                        >
                          <div
                            style="width: 80%; justify-content: flex-end"
                            class="df"
                          >
                            <el-select
                              v-model="dItem.action.colorModle"
                              placeholder="请选择"
                            >
                              <el-option
                                v-for="item in [
                                  {
                                    value: '1',
                                    label: '常亮',
                                  },
                                  {
                                    value: '2',
                                    label: '慢闪',
                                  },
                                  {
                                    value: '3',
                                    label: '快闪',
                                  },
                                ]"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </el-form-item>
                        <div
                          style="
                            padding: 10px 0px;
                            margin-top: 10px;
                            border-top: 1px solid #eee;
                          "
                        >
                          <el-form-item
                            label="时间(s)"
                            class="mt10"
                            prop="time"
                            :rules="{
                              required: true,
                              message: '时间不能为空',
                              trigger: 'blur',
                            }"
                          >
                            <div style="width: 80%">
                              <el-input v-model="dItem.time"></el-input>
                            </div>
                          </el-form-item>
                        </div>
                      </el-form>
                    </el-card>
                  </template>
                </div>
              </el-form-item>
            </div>
          </div>
          <el-form-item>
            <div
              class="df"
              style="justify-content: flex-end; width: 88.5%; margin-top: 10px"
            >
              <el-button
                type="success"
                size="medium"
                style="height: 40px; border: none !important; margin-left: 20px"
                @click="submit"
                >提交</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/util/extra-api";

export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      dialogTitle: "新增",
      tableData: [],
      total: 0,
      dialogVisible: false,
      list: [],
      componentList: [
        {
          key: 1,
          name: "步进电机",
          action: {
            position: "",
            seepd: "",
          },
          time: "",
        },
        {
          key: 2,
          name: "油温控制",
          action: {
            switch: false,
            temperature: "",
          },
          time: "",
        },
        {
          key: 3,
          name: "糖温控制",
          action: {
            switch: false,
            temperature: "",
          },
          time: "",
        },
        {
          key: 4,
          name: "延时",
          action: {
            delay: "",
          },
          time: "",
        },
        {
          key: 5,
          name: "蜂鸣器",
          action: {
            sound: false,
          },
          time: "",
        },
        {
          key: 6,
          name: "rgb灯",
          action: {
            color: "",
            colorModle: "",
          },
          time: "",
        },
        {
          key: 7,
          name: "消杀控制",
          action: {
            concentration: 2,
          },
          time: "",
        },
      ],
      form: {
        name: "",
        steps: [[]],
      },
      // sliderMarks: {
      //   2: "50",
      //   3: "100",
      //   4: "150",
      //   5: "200",
      //   6: "250",
      //   7: "300",
      // },
      editId: null,
    };
  },
  mounted() {
    this.getModeList();
  },
  computed: {
    componentListComputed() {
      return this.form.steps.map((item, index) => {
        let keys = item.map((v) => v.key);
        let data = null;
        if (keys.length) {
          data = this.componentList.filter((v) => !keys.includes(v.key));
        } else {
          data = this.componentList;
        }
        return data;
      });
    },
  },
  methods: {
    handleEdit(row) {
      this.editId = row.id;
      console.log(this.editId, 123);
      this.form.name = row.modeName;
      this.form.steps = JSON.parse(row.modeStep).map((v) => {
        v.map((item) => {
          if (item.key === 6) {
            // console.log(String(item.action.color).slice(0, -1));
            // console.log(String(item.action.color).slice(-1));
            item.action.color =
              "#" +
              Number(String(item.action.color).slice(-1))
                .toString(16)
                .toUpperCase();
            item.action.colorModle = String(item.action.color).slice(-1);
          }
          return item;
        });
        return v;
      });
      this.dialogTitle = "修改";
      this.dialogVisible = true;
    },
    delStep(index) {
      this.form.steps.splice(index, 1);
    },
    addItem() {
      this.form.steps.push([]);
    },
    delComponent(index, data) {
      console.log(data, index, this.form.steps[index]);
      this.$set(
        this.form.steps,
        index,
        this.form.steps[index].filter((v) => v.key !== data.key)
      );
    },
    addComponent(command, index) {
      // console.log(Object.keys(command));

      this.form.steps[index].push(JSON.parse(JSON.stringify(command)));
    },
    delItem(row) {
      this.dialogTitle = "修改";
      api.post(`/v1/pc/device/deleteMode`, { id: row.id }).then((res) => {
        if (this.list.length === 1 && this.query.page > 1) {
          this.query.page--;
        }
        this.getModeList();
      });
    },
    getModeList() {
      api.get("/v1/pc/device/modeList", this.query).then((res) => {
        this.list = res.data;
        this.total = res.total;
      });
    },
    onSubmit() {
      this.query.page = 1;
      this.getModeList();
    },
    pageChange(page) {
      this.query.page = page;
      this.getModeList();
    },
    submit() {
      let flag = true;
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          flag = false;
          console.log("error submit!!");
          return false;
        }
      });
      this.$refs["form1"].forEach((v) => {
        v.validate((valid) => {
          if (!valid) {
            flag = false;
            console.log("error submit!!");
            return false;
          }
        });
      });
      // console.log(this.$refs["form1"]);
      if (flag) {
        let data = JSON.parse(JSON.stringify(this.form.steps));
        data = data.map((item, index) => {
          let res = item.map((v) => {
            if (v.key === 6) {
              v.action.color =
                Number(v.action.color.replace("#", "0x")) +
                String(v.action.colorModle);
            }
            if (v.key === 7) {
              v.action.concentration = Number(v.action.concentration);
            }
            return v;
          });
          return res;
        });
        if (this.dialogTitle === "新增") {
          api
            .post("/v1/pc/device/addMode", {
              modeName: this.form.name,
              modeStep: JSON.stringify(data),
            })
            .then((res) => {
              if (res.code == 200) {
                this.getModeList();
                this.dialogVisible = false;
                this.form = {
                  name: "",
                  steps: [[]],
                };
              }
            });
        } else {
          api
            .post("/v1/pc/device/updateMode", {
              id: this.editId,
              modeName: this.form.name,
              modeStep: JSON.stringify(data),
            })
            .then((res) => {
              if (res.code == 200) {
                this.getModeList();
                this.dialogVisible = false;
                this.form = {
                  name: "",
                  steps: [[]],
                };
              }
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.jcb {
  justify-content: space-between;
}
/deep/ .el-card__header {
  padding: 10px;
}
/deep/ .el-slider__marks-text {
  width: 30px !important;
}
</style>